<template>

    <div v-if="alerts" class="flex flex-col justify-center items-center">
        <div class="mt-3 flex items-center w-full">
            <div class="relative w-1/3"></div>
            <div class="relative w-1/3 flex justify-between text-2xl items-center">
                <div @click="filterByAlert('mild')" class="px-6 cursor-pointer" :class="{ 'opacity-zeus-30': selectAlert.length!==0&&selectAlert !== 'mild' }">
                    {{ totals.mild }}
                    <i class="ml-1 text-font-gray mdi mdi-alert-circle-outline relative" >
                        <span class="h-2 w-2 absolute top-0 right-0 rounded-full bg-green-dark"></span>
                    </i>
                </div>
                <div @click="filterByAlert('medium')" class="px-6 cursor-pointer" :class="{ 'opacity-zeus-30': selectAlert.length!==0&&selectAlert !== 'medium' }">
                    {{ totals.medium }}
                    <i class="ml-1 text-font-gray mdi mdi-reload-alert relative">
                        <div class="h-2 w-2 absolute top-0 right-0 rounded-full bg-warning"></div>
                    </i>
                </div>
                <div @click="filterByAlert('serious')" class="px-6 cursor-pointer" :class="{ 'opacity-zeus-30': selectAlert.length!==0&&selectAlert !== 'serious' }">
                    {{ totals.serious }}
                    <i class="ml-1 text-font-gray mdi mdi-alert relative">
                        <div class="h-2 w-2 absolute top-0 right-0 rounded-full bg-danger"></div>
                    </i>
                </div>
            </div>
            <div class="w-1/3 flex justify-end">
                <div class="relative w-84">
                    <div class="relative flex-none w-84">
                        <input type="text" placeholder="Search" v-model="searchInput"
                            class="w-full h-8 rounded bg-box-40 flex flex-row justify-start
                            items-center px-2">
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-8 h-full w-full overflow-hidden relative">
            <div class="scroll-gradient"></div>
            <div class="h-full overflow-y-auto">
                <div class="flex flex-wrap -m-2">
                    <div v-for="(alert, index) in search(filteredAlerts)" :key="index"
                        class="w-1/3 p-2 overflow-hidden">
                        <div class="w-full h-56 box px-4 py-3 flex flex-col hadow-md"
                            :class="{ 'bg-danger-15': alert.notification_type === 'serious' }">
                            <div class="flex justify-between items-center">
                                <i class="mdi text-xl" :class="iconByNotificationType(alert.notification_type)"></i>
                                <div class="">
                                    <span class="text-tiny text-font-gray"
                                        :class="{ 'text-danger': alert.notification_type === 'serious' }">
                                        {{ alert.date |  moment('MM/DD/YYYY') }}
                                    </span>
                                </div>
                            </div>
                            <div class="flex-1 min-h-0 px-10 py-2 font-thin leading-normal overflow-y-auto">
                                <p class="module-title text-xl font-semibold truncate-2">{{ alert.title }}</p>
                                <p class="mt-1 text-base text-font-light text-tiny  mr-10aw"
                                v-html="alert.body"></p>
                            </div>
                            <div class="h-6 flex mt-2 flex-row justify-end items-center">
                                <i v-show="alert.link !== ''" class="mdi mdi-open-in-new text-font-gray
                                    cursor-pointer text-2xl mr-2" @click="goLink(alert.link)"></i>
                                <i v-show="alert.file !== ''" class="mdi text-2xl cursor-pointer"
                                   :class="extension(alert.file)" @click="openFile(alert.file, 'file')"></i><span class="ml-2 text-lg">{{ alert.likes.length }}</span>
                                <div class=" h-8 w-8 flex flex-col justify-center items-center cursor-pointer mr-1" @click="dolike(alert.id)">
                                    <i class="mdi mdi-thumb-up-outline text-font-gray text-xl" :class="meLike(alert.likes)"></i>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="pdfSelected" class="absolute w-3/5 h-192 z-50 flex flex-col bg-body
            rounded-md shadow-md">
            <div class="h-10 flex flex-row justify-between items-center px-2">
                <span class="text-md text-white font-semibold">{{ fileOpen }}</span>
                <i class="mdi mdi-close text-white font-semibold cursor-pointer"
                    @click="openFile()"></i>
            </div>
            <div class="flex-1">
                <iframe id="iframepdf" :src="pdfSelected" class="h-full w-full"></iframe>
            </div>
        </div>
    </div>
    <div v-else class="h-full w-full flex flex-col justify-center items-center">
        <loader :loading="loading" />
    </div>
</template>

<script>

import { state } from '@/store';
import pdf from 'vue-pdf';

export default {
    components: {
        pdf
    },
    data() {
        return {
            alerts: null,
            loading: false,
            selectAlert: [],
            totals: {
                serious: 0,
                medium: 0,
                mild: 0
            },
            searchInput: '',
            pdfSelected: false,
            fileOpen: false,
            allAlerts: false
        }
    },
    methods: {
        dolike(id) {
            this.axios.post(process.env.VUE_APP_BACKEY_CMS_URL + 'like?resource=Alert&id='+id)
                .then(response => {
                    this.load();
                })
        },
        meLike(alerts) {
            return this.$meLike(alerts)
        },
        iconByNotificationType (notification) {
            return this.$iconByNotification(notification)
        },
        filterByAlert(alert) {
            if (alert === this.selectAlert) this.selectAlert = []
            else this.selectAlert = alert
        },
        load() {
            this.loading = true
            this.axios.get(process.env.VUE_APP_BACKEY_CMS_URL + 'list?resource=Alert&sorts[date]=DESC', {params: state.globalParams})
                .then(response => {

                    this.allAlerts = response.data
                    this.alerts = response.data.filter((alert) => alert.active == 1);
                    const { serious, medium, mild }  = this.$alertsCount(this.alerts)
                    this.totals.serious = serious
                    this.totals.medium = medium
                    this.totals.mild = mild
                    this.loading = false
                })
        },
        extension(file) {
            let result = ''
            result += this.$iconByExtension(file)
            return result
        },
        search(data) {
            return data.filter(post => {
                return post['title'].toLowerCase().
                    includes(this.searchInput.toLowerCase()) || post['body'].toLowerCase().
                    includes(this.searchInput.toLowerCase())
            }) 
        },
        openFile(file, type) { this.$openFile(file, type) },
        goLink(link) { this.$goLink(link) },
    },
    computed: {
        filteredAlerts() {
            if (this.selectAlert.length === 0) { return this.alerts }
            return this.alerts.filter(({ notification_type }) => {
                return this.selectAlert.includes(notification_type);
            });
        },
        params() {
            let params = state.globalParams
            if (this.$route.params.catId) {
                params = {...params, ...{ sectorAmbiciona: this.$route.params.catId }}
            }
            return params
        }
    },
    watch: {
        params() {
            this.load();
        }
    },
    mounted() {
        this.load();

        setInterval(() => {
            this.load();
        }, state.refreshPersonalDashboard);
    }
}
</script>

<style>
    .a{
        color: rgb(254, 105, 78);
    }
</style>